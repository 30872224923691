// consumer_excessive_trainTicket_deptAnalyse 火车票-部门分析
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
//
const consumer_excessive_trainTicket_deptAnalyse = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/excessive/trainTicket/deptAnalyse',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_excessive_trainTicket_deptAnalyse;