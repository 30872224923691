import consumer_excessive_trainTicket_deptAnalyse from '@/lib/data-service/haolv-default/consumer_excessive_trainTicket_deptAnalyse'
import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
import moment from "moment";
import Pagination from '@/component/pagination/index.vue'
export default {
    data () {
        return {
            loading: false,
            params: {
                deptId: '',
                deptName: '',
                startTime: '',
                endTime: '',
                mold: '',
                type: '',
                dateArr: [],
                deptIdArr: [],
                timeList: [],
                pageSize: 10,
                currentPage: 1,
                total: 0
            },
            options: [],
            pickerOptions: {
                disabledDate: this.disabledDate,
                onPick: this.onPick
            },
            timeOptionRange: null,
            analyseList: [],
        }
    },
    components: {
        Pagination
    },
    created () {},
    mounted () {},
    activated () {
        this.getDepartmentList()
        this.setTimeList()
        this.getDeptAnalyse()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 获取部门列表
        getDepartmentList() {
            consumer_department_getDepartmentList().then(res => {
                this.options = res.datas.departmentListResults
            })
        },
        changeDeptId(val) {
            console.log(val)
            let _this = this
            function getDeptName(list, activeId) {
                list.forEach(value => {
                    if (value.deptId === activeId) {
                        _this.params.deptName = value.deptName
                    }
                    if (value.childrenList && value.childrenList.length > 0) {
                        getDeptName(value.childrenList, activeId)
                    }
                })
            }
            if (val.length === 0) {
                this.params.deptId = ''
                this.params.deptName = ''
            } else {
                this.params.deptId = val[0]
                getDeptName(this.options, val[0])
            }
        },
        setTimeList() {
            if (this.params.timeList.length === 0) {
                let now = moment()
                let year = now.year()
                let month = now.month()
                console.log(year, month)
                let timeList = []
                for (let i = 1, l = month + 2; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }
                console.log(timeList)
                this.params.timeList = timeList
            }
        },
        getDeptAnalyse() {
            this.loading = true
            consumer_excessive_trainTicket_deptAnalyse(this.params).then(res => {
                this.loading = false
                this.analyseList = res.pageResult.pageData
                this.params.total = res.pageResult.totalCount
            }).catch(() => {
                this.loading = false
                this.analyseList = []
                this.params.total = 0
            })
        },
        changeOrderDate(val) {
            console.log(val)
            if (val === '' || val === null) {
                this.params.startTime = ''
                this.params.endTime = ''
                this.params.timeList = []
                this.setTimeList()
            } else {
                let bigMonth = ['01', '03', '05', '07', '08', '10', '12']
                let currentMonth = val[0].split('-')[1]

                this.params.startTime = val[0] + '-01'
                this.params.endTime = `${val[1]}-${bigMonth.indexOf(currentMonth) > -1 ? '31' : '30'}`

                let year = this.params.startTime.split('-')[0]
                let startMonth = parseInt(this.params.startTime.split('-')[1])
                let endMonth = parseInt(this.params.endTime.split('-')[1])
                let timeList = []
                for (let i = startMonth, l = endMonth + 1; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    }
                    timeList.push(item)
                }
                console.log(timeList)
                this.params.timeList = timeList
            }
        },
        search() {
            this.params.currentPage = 1
            this.getDeptAnalyse()
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/